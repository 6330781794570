var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resource-item"},[_c('div',{class:{
      'hm-form__element-wrapper': true,
      'resource-item__element': true,
      'hm-form__element-wrapper--error': _vm.validateErrorResource
    }},[_c('esmp-select',{attrs:{"placeholder":"Выберите ресурс","filterable":"","loading":_vm.isLoading,"show-search-result":_vm.showSearchResult,"default-label":_vm.val.resource && _vm.val.resource.name ? _vm.val.resource.name : _vm.query,"remote-method":_vm.searchResource},on:{"on-query-change":_vm.setQuery,"scroll":_vm.onScroll},model:{value:(_vm.val.resource),callback:function ($$v) {_vm.$set(_vm.val, "resource", $$v)},expression:"val.resource"}},_vm._l((_vm.resources),function(r){return _c('esmp-select-option',{key:r.id,attrs:{"value":r,"label":r.name}},[_c('div',{staticClass:"resource__info"},[_c('div',{staticClass:"resource__name"},[_vm._v(" "+_vm._s(r.name)+" "),(r.regionalBranch)?_c('span',{staticClass:"resource__branch"},[_vm._v(_vm._s(r.regionalBranch))]):_vm._e()]),(r.groupNode)?_c('div',{staticClass:"resource__group-node"},[_vm._v(" "+_vm._s(r.groupNode)+" ")]):_vm._e()])])}),1),(_vm.validateErrorResource)?_c('div',{staticClass:"hm-form__element-hint"},[_vm._v(" "+_vm._s(_vm.validateErrorResource)+" ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAccessType),expression:"isShowAccessType"}],class:{
      'hm-form__element-wrapper': true,
      'resource-item__element': true,
      'resource-item__element_roles': true,
      'hm-form__element-wrapper--error': _vm.validateErrorRole
    }},[_c('esmp-select',{attrs:{"filterable":"","multiple":_vm.showAddRole,"placeholder":"Выберите Доступ/Роль","value":_vm.defaultRoleValue},on:{"on-change":_vm.onRoleChange}},_vm._l((_vm.roles),function(role){return _c('esmp-select-option',{key:role.id,attrs:{"value":role.id,"label":role.name}},[_vm._v(" "+_vm._s(role.name)+" ")])}),1),_c('div',{staticClass:"hm-form__element-hint"},[_vm._v(" "+_vm._s(_vm.validateErrorRole)+" ")])],1),(_vm.blocksLength > 1)?_c('esmp-button',{staticClass:"resource-item__element resource-item__element_delete",attrs:{"size":"small"},on:{"click":function($event){return _vm.$emit('remove', _vm.id)}}},[_vm._v(" Удалить ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }