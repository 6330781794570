<template>
  <div class="resource-field">
    <div class="resource-field__elements" v-if="blocks && blocks.length">
      <div
        class="resource-field__element"
        v-for="(block, index) in blocks"
        :key="block.id"
        :data-key="block.id"
      >
        <div class="resource-field__element-row">
          <resource-item
            :value="value[index] ? value[index] : null"
            :id="block.id"
            :roles="roles"
            :show-add-role="showAddRole"
            :is-show-access-type="isShowAccessType"
            :default-role="defaultValue"
            :resource-group-name="resourceGroupName"
            :blocks-length="blocks.length"
            :validate-errors="validateErrors"
            @change="($event) => onResourceChange(block.id, $event)"
            @remove="removeBlock"
          />
        </div>
      </div>
      <div class="resource-field__element-row" v-if="showAddResource">
        <esmp-button @click="addBlock" size="small">
          Добавить ресурс
        </esmp-button>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceItem from './ResourceItem.vue';

export default {
  name: 'Resource',
  components: { ResourceItem },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: [String, Object],
      default: undefined,
    },
    controlId: {
      type: [String, Number],
      default: undefined,
    },
    resourceGroupName: {
      type: String,
      default: undefined,
    },
    showAddResource: {
      type: Boolean,
      default: false,
    },
    showAddRole: {
      type: Boolean,
      default: false,
    },
    isShowAccessType: {
      type: Boolean,
      default: true,
    },
    validateErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resources: null,
      roles: null,
      source: null,
      blocks: this.value && this.value.length ? this.value : [
        {
          id: +new Date(),
          resource: null,
          roles: [],
        },
      ],
      loading: false,
      showSearchResult: false,
    };
  },
  methods: {
    generateBlock() {
      return {
        id: +new Date(),
        resource: null,
        roles: [],
      };
    },
    addBlock() {
      this.blocks.push(this.generateBlock());
    },
    removeBlock(id) {
      this.blocks = this.blocks.filter((el) => el.id !== id);
      this.$emit('change', this.blocks);
    },
    onResourceChange(id, val) {
      this.blocks = this.blocks.map((el) => {
        if (el.id === id) {
          return {
            id: val.id,
            resource: val.resource,
            roles: val.roles,
          };
        }
        return el;
      });
      this.$emit('change', this.blocks);
    },
  },
  created() {
    this.$API.resource.getRolesByGroup({
      source: 'otrs1',
      groupName: this.resourceGroupName,
    }).then(({ data }) => {
      this.roles = data;
    });
  },
};
</script>
<style lang="scss" scoped>
.resource {
  &-field__element {
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0;
    }
    &-row {
      margin: 0 0 20px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
