<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :mode="validationMode"
    ref="provider"
    :vid="vid"
  >
    <div class="hm-form__element-wrapper">
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <resource
        v-model="localValue"
        :default-value="defaultValue"
        :control-id="id"
        :resource-group-name="resourceGroupName"
        :show-add-resource="showAddResource"
        :show-add-role="showAddRole"
        :is-show-access-type="isShowAccessType"
        :validate-errors="v.failedRules"
        @change="onInput"
      />
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import Resource from './Resource.vue';

export default {
  name: 'HmResource',
  mixins: [uid],
  components: { Resource },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    defaultValue: {
      type: [String, Object],
      default: undefined,
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    resourceGroupName: {
      type: String,
      default: undefined,
    },
    showAddResource: {
      type: Boolean,
      default: false,
    },
    showAddRole: {
      type: Boolean,
      default: false,
    },
    isShowAccessType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      validationMode: 'passive',
      changeCounter: 0,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
      this.changeCounter += 1;
      if (this.changeCounter > 1) {
        this.validationMode = 'aggressive';
        this.$refs.provider.validate();
      }
    },
  },
  mounted() {
    // Если уже есть значение, то вызвать обновление модели и, соответственно, отображение связанных групп
    if (this.value) {
      this.onInput(this.value);
    }
  },
};
</script>
