<template>
  <div class="resource-item">
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'resource-item__element': true,
        'hm-form__element-wrapper--error': validateErrorResource
      }"
    >
      <esmp-select
        placeholder="Выберите ресурс"
        filterable
        :loading="isLoading"
        :show-search-result="showSearchResult"
        v-model="val.resource"
        :default-label="val.resource && val.resource.name ? val.resource.name : query"
        :remote-method="searchResource"
        @on-query-change="setQuery"
        @scroll="onScroll"
      >
        <esmp-select-option
          v-for="r in resources"
          :key="r.id"
          :value="r"
          :label="r.name"
        >
          <div class="resource__info">
            <div class="resource__name">
              {{ r.name }}
              <span v-if="r.regionalBranch" class="resource__branch">{{ r.regionalBranch }}</span>
            </div>
            <div v-if="r.groupNode" class="resource__group-node">
              {{ r.groupNode }}
            </div>
          </div>
        </esmp-select-option>
      </esmp-select>
      <div v-if="validateErrorResource" class="hm-form__element-hint">
        {{ validateErrorResource }}
      </div>
    </div>
    <div
      v-show="isShowAccessType"
      :class="{
        'hm-form__element-wrapper': true,
        'resource-item__element': true,
        'resource-item__element_roles': true,
        'hm-form__element-wrapper--error': validateErrorRole
      }"
    >
      <esmp-select
        filterable
        :multiple="showAddRole"
        placeholder="Выберите Доступ/Роль"
        :value="defaultRoleValue"
        @on-change="onRoleChange"
      >
        <esmp-select-option
          v-for="role in roles"
          :key="role.id"
          :value="role.id"
          :label="role.name"
        >
          {{ role.name }}
        </esmp-select-option>
      </esmp-select>
      <div class="hm-form__element-hint">
        {{ validateErrorRole }}
      </div>
    </div>
    <esmp-button
      v-if="blocksLength > 1"
      class="resource-item__element resource-item__element_delete"
      size="small"
      @click="$emit('remove', id)"
    >
      Удалить
    </esmp-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'ResourceItem',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    blocksLength: {
      type: Number,
      default: 1,
    },
    defaultRole: {
      type: [String, Object],
      default: undefined,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    resourceGroupName: {
      type: String,
      default: undefined,
    },
    showAddRole: {
      type: Boolean,
      default: false,
    },
    isShowAccessType: {
      type: Boolean,
      default: true,
    },
    validateErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      val: this.value || {
        id: this.id,
        resource: null,
        roles: [{ ...this.defaultRole }],
      },
      resources: this.value?.resource ? [this.value.resource] : [],
      source: null,
      blocks: [],
      loadStack: [],
      showSearchResult: false,
      changeCounter: 0,
      query: '',
      oldQuery: '',
      page: 0,
      totalPages: null,
    };
  },
  computed: {
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    customerLogin() {
      return this.selectedOrLoggedInUser.login || this.selectedOrLoggedInUser.employeeNumber;
    },
    defaultRoleValue() {
      if (this.showAddRole) {
        if (this.val.roles && this.val.roles.length) {
          return [...this.val.roles.map((el) => el.id)];
        }
        return this.defaultRole && this.changeCounter < 2 ? [this.defaultRole.id] : [];
      }
      if (this.val.roles && this.val.roles[0]) {
        return this.val.roles[0].id;
      }
      return this.defaultRole ? this.defaultRole.id : '';
    },
    validateErrorResource() {
      if (this.validateErrors && this.validateErrors.required && !this.val.resource) {
        return 'Поле обязательно для заполнения';
      }
      if (this.validateErrors && this.validateErrors.resource && !this.val.resource) {
        return 'Необходимо добавить ресурс';
      }

      return '';
    },
    validateErrorRole() {
      if (this.validateErrors
        && this.validateErrors.required
        && (isEmpty(this.val.roles)
        || isEmpty(this.val.roles[0]))
      ) {
        return 'Поле обязательно для заполнения';
      }
      if (this.validateErrors
        && this.validateErrors.resource
        && (isEmpty(this.val.roles)
        || isEmpty(this.val.roles[0]))
      ) {
        return 'Необходимо добавить роль';
      }

      return '';
    },
    isLoading() {
      return !!this.loadStack.length;
    },
  },
  methods: {
    setQuery(q) {
      this.query = q;
    },
    onScroll(e) {
      const { scrollHeight, scrollTop, offsetHeight } = e.target;
      if (
        scrollTop + offsetHeight === scrollHeight
        && this.page + 1 < this.totalPages && !this.isLoading
      ) {
        this.page += 1;
        this.searchResource(this.query, false);
      }
    },
    // eslint-disable-next-line
    searchResource: debounce(function (q, resetState = true) {
      this.val.resource = null;
      if (q && q.length > 1) {
        const key = Number(new Date());
        this.loadStack.push(key);

        this.$API.resource.cancelSearchGroup();
        if (resetState) {
          this.resources = [];
          this.page = 0;
          this.totalPages = null;
        }
        this.oldQuery = this.query;
        this.$API.resource.searchGroup({
          searchResourceQuery: this.query,
          source: 'otrs1',
          resourceGroupName: this.resourceGroupName,
          customerLogin: this.customerLogin,
          page: this.page,
          size: 20,
        }).then(({ data }) => {
          this.showSearchResult = true;
          this.totalPages = data.totalPages;
          if (!resetState) {
            this.resources.push(...data.content);
          } else {
            this.resources = data.content;
          }
        }).finally(() => {
          const index = this.loadStack.findIndex((i) => i === key);
          this.loadStack.splice(index, 1);
        });
      }
    }, SEARCH_DELAY),
    onRoleChange(val) {
      if (Array.isArray(val)) {
        this.val.roles = this.roles.filter((el) => val.includes(el.id));
      } else {
        this.val.roles = this.roles.filter((el) => el.id === val);
      }
    },
  },
  watch: {
    val: {
      handler(v) {
        this.$emit('change', v);
        this.changeCounter += 1;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.resource {
  &-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include for-size(phone-portrait-down) {
      flex-direction: column;
    }
    &__element {
      width: 100%;

      &_roles, &_delete {
        margin-left: 20px;
        @include for-size(phone-portrait-down) {
          margin-left: 0;
          margin-top: 8px;
        }
      }
      &_delete {
        width: auto;
        margin-top: 8px;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
  }
  &__branch {
    display: inline-block;
    margin: 0 0 0 16px;
    color: rgba(16, 24, 40, 0.4);
  }
  &__group-node {
    color: $color-primary-1-day;
  }
  &__branch, &__group-node {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
